jQuery(function ($) {
    console.log('cargando sliders 1');
    if ($('#clients-slider-logos .logos_container').length > 0) {
        jQuery('#clients-slider-logos .logos_container').slick({
            dots: false,
            prevArrow: jQuery('.prev'),
            nextArrow: jQuery('#clients-slider-logos .next'),
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            asNavFor: '#clients-slider-thumbnails',
            focusOnSelect: true,
            variableWidth: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 767, // Para pantallas menores o iguales a 767px
                    settings: {
                        variableWidth: false, // Desactiva variableWidth en móviles
                        slidesToShow: 1, // Opcional: puedes ajustar el número de slides visibles
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    if ($('#clients-slider-thumbnails').length > 0) {
        jQuery('#clients-slider-thumbnails').slick({
            dots: false,
            prevArrow: jQuery('.prev'),
            nextArrow: jQuery('.next'),
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            asNavFor: '#clients-slider-logos .logos_container',
            centerMode: false,
            focusOnSelect: true
        });
    }
    if ($('.two_columns_slider .sliders .sliders_container').length > 0) {
        jQuery('.two_columns_slider .sliders .sliders_container').slick({
            dots: false,
            prevArrow: jQuery('.two_columns_slider .sliders .prev'),
            nextArrow: jQuery('.next'),
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            focusOnSelect: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 767, //
                    settings: {
                        variableWidth: false, //
                        slidesToShow: 2, // 
                        slidesToScroll: 1
                    }
                }
                , {
                    breakpoint: 580, //
                    settings: {
                        variableWidth: false, //
                        slidesToShow: 1, // 
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
});
