document.addEventListener('DOMContentLoaded', function() {
    if (document.querySelectorAll('.video_container').length > 0) {
        const videoContainers = document.querySelectorAll('.video_container');
        
        videoContainers.forEach(container => {
            let videoUrl = container.getAttribute('data-video-url');
            if (videoUrl !== '#' && videoUrl !== '') {
                console.log(videoUrl);
    
                // Extraer ID de video, incluso si contiene un hash
                const vimeoRegex = /vimeo\.com\/(\d+)(?:\/\w+)?/;
                const match = videoUrl.match(vimeoRegex);
                if (match && match[1]) {
                    const videoId = match[1];
                    videoUrl = `https://player.vimeo.com/video/${videoId}`; // URL del iframe
                }
        
                const playButton = container.querySelector('.play_video');
                
                playButton.addEventListener('click', function() {
                    // Crear y mostrar el popup con el iframe
                    const popup = document.createElement('div');
                    popup.className = 'video_popup';
                    popup.innerHTML = `
                        <div class="popup_overlay"></div>
                        <div class="popup_content">
                            <div class="iframe_content">
                                <button class="close_popup">Close</button>
                                <iframe src="${videoUrl}?autoplay=1&muted=0" width="560" height="315" frameborder="0" allow="autoplay; fullscreen"></iframe>
                            </div>
                        </div>
                    `;
                    document.body.appendChild(popup);
        
                    // Cerrar popup
                    const closePopupBtn = popup.querySelector('.close_popup');
                    closePopupBtn.addEventListener('click', function() {
                        popup.remove();
                    });
                });
            }
        });
    }
    
    if (document.querySelector('#bg-banner-video')) {
        let bannerVideo = document.querySelector('#bg-banner-video');
        let videoAdded = false; // Variable para asegurarse de que el iframe solo se agregue una vez
    
        function isInViewport(element, percentage = 0.7) {
            const rect = element.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const triggerPoint = windowHeight * percentage;
    
            return rect.top <= triggerPoint && rect.bottom >= 0;
        }
    
        let videoUrl = 'https://vimeo.com/1021051217';
        
        // Validar y construir la URL del iframe de Vimeo
        if (videoUrl !== '#' && videoUrl !== '') {
            const vimeoRegex = /vimeo\.com\/(\d+)/;
            const match = videoUrl.match(vimeoRegex);
            if (match && match[1]) {
                const videoId = match[1];
                videoUrl = `https://player.vimeo.com/video/${videoId}`;
            }
        }
    
        // Función para agregar el iframe solo una vez
        function addVideo() {
            if (!videoAdded && isInViewport(bannerVideo)) {
                const iframe = document.createElement('iframe');
                iframe.src = `${videoUrl}?autoplay=1&muted=1&background=1`; // 'background=1' oculta controles para usar como fondo
                iframe.width = "1129"; // Ajustar tamaño según sea necesario
                iframe.height = "508"; // Ajustar tamaño según sea necesario
                iframe.allow = "autoplay; fullscreen";
                iframe.frameBorder = "0";
                iframe.setAttribute('allowfullscreen', 'true'); // Asegurar que permita fullscreen
    
                // Agregar iframe solo una vez
                bannerVideo.appendChild(iframe);
                videoAdded = true; // Marcar como añadido para evitar duplicados
    
                // Opcional: remover el listener de scroll si no se necesita más
                window.removeEventListener('scroll', onScroll);
            }
        }
    
        // Función manejadora del evento scroll
        function onScroll() {
            addVideo();
        }
    
        // Escuchar el evento scroll
        window.addEventListener('scroll', onScroll);
    
        // Ejecutar al cargar la página en caso de que el video ya esté visible
        addVideo();
    }
    
});