class AnimatedElement {
    constructor(element){
        this.element = element;
        this.selector = '';//selector
        this.animation = '';// tipo de animacion
        this.delay = 200;//retraso
        this.percentage = 0.5;
    }
    createSelector(){
        //creara el selector a partir de las clases enviadas en la seccion
        const clases = this.element.classList;
        let strSelector = '';

        //repetir este bucle hasta validar todas las clases de la seccion
        for (let index = 0; index < clases.length; index++) {
            const clase = clases[index];
            const addTag = (tag)=>{
                if(strSelector != ''){
                    strSelector += ` ${tag}`;
                }else{
                    strSelector += tag;
                }
            }
            if(clase == 'p'){
                addTag('p');
            }
            else if(clase == 'a'){
                addTag('a');
            }
            else if(clase == 'h1'){
                addTag('h1')
            }
            else if(clase == 'h2'){
                addTag('h2')
            }
            else if(clase == 'h3'){
                addTag('h3')
            }
            else if(clase == 'h4'){
                addTag('h4')
            }
            else if(clase == 'h5'){
                addTag('h5')
            }
            else if(clase == 'li'){
                addTag('li')
            }
            else if(clase == 'strong'){
                addTag('strong')
            }
            else if(clase == 'span'){
                addTag('span')
            }
        }
        this.selector = strSelector;
    }

    setAnimation(){
        //seleccionara la animacion del elemento a partir de las clases
        if(this.element.classList.contains('fade-in')){
            this.animation = 'fade-in';
        }

    }

    setDelay(){
        //establecera el delay entre los elementos a animar
        if (this.element.classList.contains('d-20')) {
            this.delay = 20; //para span
        } else if (this.element.classList.contains('d-50')) {
            this.delay = 50;
        } else if (this.element.classList.contains('d-100')) {
            this.delay = 100; //para enlaces
        }
        else if (this.element.classList.contains('d-200')) {
            this.delay = 200;
        } 
        else if (this.element.classList.contains('d-300')) {
            this.delay = 300;
        }
    }
    setPercentage(){
        if (this.element.classList.contains('p-10')) {
            this.percentage = 0.1; 
        } else if (this.element.classList.contains('p-20')) {
            this.percentage = 0.2; 
        } else if (this.element.classList.contains('p-30')) {
            this.percentage = 0.3;
        }
        else if (this.element.classList.contains('p-40')) {
            this.percentage = 0.4;
        } 
        else if (this.element.classList.contains('p-50')) {
            this.percentage = 0.5;
        }
        else if (this.element.classList.contains('p-60')) {
            this.percentage = 0.6;
        }
        else if (this.element.classList.contains('p-70')) {
            this.percentage = 0.7;
        }
        else if (this.element.classList.contains('p-80')) {
            this.percentage = 0.8;
        }
        else if (this.element.classList.contains('p-90')) {
            this.percentage = 0.9;
        }
    }

    getElement(){
        //retornara el objeto armado
        this.createSelector();
        this.setAnimation();
        this.setDelay();
        this.setPercentage();
        return{
            element: this.element,
            selector: this.selector,
            animation: this.animation,
            delay: this.delay,
            percentage: this.percentage
        }
    }

}

document.addEventListener("DOMContentLoaded", function () {
    // isInViewport detectara cuando el elemento este enfocado en la pantalla
    function isInViewport(element, percentage = 0.5) { //percentage es el % de la pagina en cuanto mas alto sea mas arriba la animacion se ejecutara cuando el elemento este mas alto en la pantalla
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const triggerPoint = windowHeight * percentage; // calcualr el punto donde queremos activar la animacion
    
        return rect.top <= triggerPoint && rect.bottom >= 0;
    }

    // aplicar animacion a cada elemente a cada valor que viene en delay en milisegundos
    function animateElements(elements, delay) {
        elements.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add('show');
            }, delay * index); // aplicar la animacion con un retraso entre elementos
        });
    }

    // elementos para animar
    const animatedSections = document.querySelectorAll('.animate');

    const starAnimation = (objetcElement) =>{
        if(objetcElement.animation == 'fade-in'){
            let elements = objetcElement.element.querySelectorAll(objetcElement.selector);
            elements.forEach(element => {                
                console.log(objetcElement);
                console.log(element);

                function wrapTextNodes(node) {
                    // si el nodo es de texto
                    if (node.nodeType === Node.TEXT_NODE) {
                        // obtiene el contenido del texto y lo divide en palabras, incluyendo los espacios
                        const palabras = node.textContent.split(/(\s+)/);
                        const fragment = document.createDocumentFragment();
            
                        // recorre cada palabra o espacio
                        palabras.forEach(palabra => {
                            if (/\s+/.test(palabra)) {
                                // si es solo un espacio, lo agrega sin cambios
                                fragment.appendChild(document.createTextNode(palabra));
                            } else {
                                // si es una palabra, la envuelve en <span>
                                const span = document.createElement('span');
                                span.classList.add('tta');
                                span.textContent = palabra;
                                fragment.appendChild(span);
                            }
                        });
            
                        // reemplaza el nodo de texto original con el fragmento con spans
                        node.parentNode.replaceChild(fragment, node);
                    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'br') {
                        // si el nodo es un <br>, no hacemos nada, simplemente lo dejamos en su lugar
                        return;
                    } else {
                        // si el nodo no es de texto, recorre sus hijos
                        Array.from(node.childNodes).forEach(child => wrapTextNodes(child));
                    }
                }
            
                // envolver las palabras en spans para todo el contenido del elemento
                Array.from(element.childNodes).forEach(child => wrapTextNodes(child));

                window.addEventListener('scroll', () => {
                    if(isInViewport(element, objetcElement.percentage)){
                        let elementsToAnimate = element.querySelectorAll('.tta');
                        animateElements(elementsToAnimate, objetcElement.delay)
                    }
                })
            });
        }
    }
    animatedSections.forEach(section=>{
        let objetcElement = new AnimatedElement(section);
        console.log(objetcElement.getElement());
        starAnimation(objetcElement.getElement());
    })

    window.addEventListener('scroll', () => {
        animatedSections.forEach((section) => {
            if (isInViewport(section)) {
                let elementType = null;
                let delay = 200; //velocidad de secuencia por defecto
                //validar el tipo de elemento si es p o es a etc
                if (section.classList.contains('p')) {
                    elementType = 'p';
                } else if (section.classList.contains('a')) {
                    elementType = 'a';
                } else if (section.classList.contains('strong')) {
                    elementType = 'strong';
                }
                else if (section.classList.contains('span')) {
                    elementType = 'span';
                } 
                else if (section.classList.contains('li')) {
                    elementType = 'li';
                }
                //clase con el retraso entre elementos
                if (section.classList.contains('d-20')) {
                    delay = 20; //para span
                } else if (section.classList.contains('d-50')) {
                    delay = 50;
                } else if (section.classList.contains('d-100')) {
                    delay = 100; //para enlaces
                }
                else if (section.classList.contains('d-200')) {
                    delay = 200;
                } 
                else if (section.classList.contains('d-300')) {
                    delay = 300;
                }

                
                if (elementType) {//si se encontro un elemento animarlo
                    const elementsToAnimate = section.querySelectorAll(elementType);
                    
                    // ejecutar animacion secuencial
                    animateElements(elementsToAnimate, delay);
                }
            }
        });
    });
});
