document.addEventListener('DOMContentLoaded', function() {
    //centrar seccion our mission
    const linkMision = document.querySelector('a[href="#our-mission"]');
    
    if (linkMision) {
        linkMision.addEventListener('click', function(event) {
            event.preventDefault();
            
            const section = document.getElementById('our-mission');
            
            const sectionTop = section.getBoundingClientRect().top + window.scrollY;
            const sectionHeight = section.offsetHeight;
            const windowHeight = window.innerHeight;
            
            window.scrollTo({
                top: sectionTop - (windowHeight / 2) + (sectionHeight / 2),
                behavior: 'smooth'
            });
        });
    }
    const link = document.querySelector('a[href="#our-values"]');
    
    if (link) {
        link.addEventListener('click', function(event) {
            event.preventDefault();
            
            const section = document.getElementById('our-values');
            
            const sectionTop = section.getBoundingClientRect().top + window.scrollY;
            const sectionHeight = section.offsetHeight;
            const windowHeight = window.innerHeight;
            
            window.scrollTo({
                top: sectionTop - (windowHeight / 2) + (sectionHeight / 2),
                behavior: 'smooth'
            });
        });
    }
    let linksHeader = document.querySelectorAll('#menu-primary .menu-item-has-children a');
    linksHeader.forEach(element=>{
        let link = element.getAttribute('href');
        element.addEventListener('click', e=>{
            window.location.href = link;
        })
    })
});
