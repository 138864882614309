document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.dark_section')) {
        let btnExpand = document.querySelector('.header h2 button');
        let rowHeader = document.querySelector('.row_header');
        let rowBodies = document.querySelectorAll('.row_body');
        let sectionDark = document.querySelector('.dark_section');
        let closeGradiednt = document.querySelector('.section_banner_gradient.closed');
        let btnCLose = document.querySelector('.row_body button');
        
        // calcular alturas
        const updateHeights = () => {
            let rowGradient = 0;
            if (closeGradiednt) {
                rowGradient = closeGradiednt.querySelector('.column').scrollHeight;
            }
            // Obtener la altura de cada rowBody
            let rowBodyHeights = Array.from(rowBodies).map(rowBody => rowBody.scrollHeight);
            return { rowBodyHeights, rowGradient };
        };

        //calcular altura inicial
        let { rowBodyHeights, rowGradient } = updateHeights();

        // cerrar todos los rowbody
        rowBodies.forEach(rowBody => rowBody.style.height = '0px');

    
        btnExpand.addEventListener('click', () => {
            rowBodies.forEach((rowBody, index) => {
                if (rowBody.classList.contains('active')) {

                    rowBody.style.height = '0px';
                    rowBody.classList.remove('active');


                    rowHeader.style.height = 'auto'; 
                } else {
                    let heights = updateHeights();
                    rowBodyHeights = heights.rowBodyHeights;
                    rowGradient = heights.rowGradient;

                    rowHeader.style.height = '0px';


                    rowBody.style.height = `${rowBodyHeights[index]}px`;
                    rowBody.classList.add('active');
                    sectionDark.classList.add('open');

                    setTimeout(() => {
                        if (closeGradiednt) {
                            closeGradiednt.querySelector('.row').style.height = `${rowGradient}px`;
                            closeGradiednt.classList.add('open');
                        }
                    }, 300); // 300ms delay
                }
            });
        });
        btnCLose.addEventListener('click', e=>{
            rowBodies.forEach((rowBody, index) => {
                if (rowBody.classList.contains('active')) {

                    rowBody.style.height = '0px';
                    rowBody.classList.remove('active');

                    
                    rowHeader.style.height = `${rowHeader.scrollHeight}px`; 
                    sectionDark.classList.remove('open');
                    rowBody.classList.remove('fit-c');
                    if (closeGradiednt) {
                        closeGradiednt.querySelector('.row').style.height = `0px`;
                        closeGradiednt.classList.remove('open');
                    }
                } else {
                    let heights = updateHeights();
                    rowBodyHeights = heights.rowBodyHeights;
                    rowGradient = heights.rowGradient;

                    rowHeader.style.height = '0px';


                    rowBody.style.height = `${rowBodyHeights[index]}px`;
                    rowBody.classList.add('active');
                    sectionDark.classList.add('open');

                    setTimeout(() => {
                        if (closeGradiednt) {
                            closeGradiednt.querySelector('.row').style.height = `${rowGradient}px`;
                            closeGradiednt.classList.add('open');
                        }
                    }, 300); // 300ms delay
                }
            });
        })

        // Agregar evento 'resize' para actualizar las clases cuando la ventana se redimensione
        window.addEventListener('resize', () => {
            // Recalcular las alturas dinámicas al redimensionar la ventana
            let heights = updateHeights();
            rowBodyHeights = heights.rowBodyHeights;
            rowGradient = heights.rowGradient;

            rowBodies.forEach((rowBody, index) => {
                if (rowBody.classList.contains('active')) {
                    // Aplicar la clase 'fit-c' en lugar de ajustar el tamaño dinámicamente
                    rowBody.classList.add('fit-c');
                }else{
                    rowBody.classList.remove('fit-c');
                }
            });

            // Si el gradient está abierto, aplicar la clase 'fit-c'
            if (closeGradiednt && closeGradiednt.classList.contains('open')) {
                closeGradiednt.querySelector('.row').classList.add('fit-c');
            }
        });
    }
});
