document.addEventListener('DOMContentLoaded', function() {
    console.log('funciona');
    const popupContainer = document.querySelector('.popup-container');
    const openPopupBtn = document.querySelector('.btn-open-popup');
    const closePopupBtn = document.querySelector('.button-close');
    if(popupContainer){
        // Abrir el popup
        openPopupBtn.addEventListener('click', function(event) {
            event.preventDefault();
            popupContainer.style.display = 'block';
            document.querySelector('body').style.overflow = 'hidden';
        });
    
        // Cerrar el popup al hacer clic en el botón de cerrar
        closePopupBtn.addEventListener('click', function() {
            popupContainer.style.display = 'none';
            document.querySelector('body').style.overflow = 'scroll';
        });
    
        // Cerrar el popup al hacer clic fuera del popup
        popupContainer.addEventListener('click', function(event) {
            if (event.target === popupContainer) {
                popupContainer.style.display = 'none';
                document.querySelector('body').style.overflow = 'scroll';
            }
        });
    }

    //funcion para crear abrir y cerrar popups a partir de la url de la imagen

    const openPopupImage = (url)=>{
        const popup = document.createElement('div');
        popup.className = 'img_popup';
        popup.innerHTML = `
        <div class="popup_overlay"></div>
        <div class="popup_content">
            <div class="image_content">
                <button class="close_popup">Close</button>
                <img src="${url}" alt="popup image">
            </div>
        </div>
        `;
        document.body.appendChild(popup);
        const closePopupBtn = popup.querySelector('.close_popup');
        closePopupBtn.addEventListener('click', function() {
            popup.remove();
        });
    }

    //popup imagenes grid two columns 
    const imagesGrid =  document.querySelectorAll('.galery_grid_group .galery_grid');
    imagesGrid.forEach(grid => {
        let btnOpneImages = grid.querySelectorAll('.btn_open_full_image');
        btnOpneImages.forEach(btnImage => {
            btnImage.addEventListener('click', e=>{
                let urlImage = btnImage.getAttribute('data-img');
                openPopupImage(urlImage);
            })
        });
    });
    const columnImagesGrid =  document.querySelectorAll('.galery_columns_section .image_container');
    columnImagesGrid.forEach(grid => {
        let btnOpneImages = grid.querySelectorAll('.btn_open_full_image');
        btnOpneImages.forEach(btnImage => {
            console.log(btnImage);
            btnImage.addEventListener('click', e=>{
                console.log('click');
                let urlImage = btnImage.getAttribute('data-img');
                console.log(urlImage);
                openPopupImage(urlImage);
            })
        });
    });
    const fullImagesContainer =  document.querySelectorAll('.full_image_container');
    fullImagesContainer.forEach(grid => {
        let btnOpneImages = grid.querySelectorAll('.btn_open_full_image');
        btnOpneImages.forEach(btnImage => {
            console.log(btnImage);
            btnImage.addEventListener('click', e=>{
                console.log('click');
                let urlImage = btnImage.getAttribute('data-img');
                console.log(urlImage);
                openPopupImage(urlImage);
            })
        });
    });
});
